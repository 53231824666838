// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-addl-info-tsx": () => import("./../../../src/pages/addl-info.tsx" /* webpackChunkName: "component---src-pages-addl-info-tsx" */),
  "component---src-pages-admin-details-tsx": () => import("./../../../src/pages/admin/details.tsx" /* webpackChunkName: "component---src-pages-admin-details-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-manage-questions-tsx": () => import("./../../../src/pages/admin/manage-questions.tsx" /* webpackChunkName: "component---src-pages-admin-manage-questions-tsx" */),
  "component---src-pages-admin-rules-tsx": () => import("./../../../src/pages/admin/rules.tsx" /* webpackChunkName: "component---src-pages-admin-rules-tsx" */),
  "component---src-pages-admin-site-tsx": () => import("./../../../src/pages/admin/site.tsx" /* webpackChunkName: "component---src-pages-admin-site-tsx" */),
  "component---src-pages-cancel-appt-tsx": () => import("./../../../src/pages/cancel-appt.tsx" /* webpackChunkName: "component---src-pages-cancel-appt-tsx" */),
  "component---src-pages-contact-info-tsx": () => import("./../../../src/pages/contact-info.tsx" /* webpackChunkName: "component---src-pages-contact-info-tsx" */),
  "component---src-pages-done-tsx": () => import("./../../../src/pages/done.tsx" /* webpackChunkName: "component---src-pages-done-tsx" */),
  "component---src-pages-have-order-tsx": () => import("./../../../src/pages/have-order.tsx" /* webpackChunkName: "component---src-pages-have-order-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mri-type-tsx": () => import("./../../../src/pages/mri-type.tsx" /* webpackChunkName: "component---src-pages-mri-type-tsx" */),
  "component---src-pages-no-order-tsx": () => import("./../../../src/pages/no-order.tsx" /* webpackChunkName: "component---src-pages-no-order-tsx" */),
  "component---src-pages-no-type-tsx": () => import("./../../../src/pages/no-type.tsx" /* webpackChunkName: "component---src-pages-no-type-tsx" */),
  "component---src-pages-pick-time-tsx": () => import("./../../../src/pages/pick-time.tsx" /* webpackChunkName: "component---src-pages-pick-time-tsx" */),
  "component---src-pages-questions-2-tsx": () => import("./../../../src/pages/questions-2.tsx" /* webpackChunkName: "component---src-pages-questions-2-tsx" */),
  "component---src-pages-safety-questions-tsx": () => import("./../../../src/pages/safety-questions.tsx" /* webpackChunkName: "component---src-pages-safety-questions-tsx" */)
}

